import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Loading from "components/loading/Loading";
import { useParams } from "react-router-dom";

export const Infinite = () => {
  const {id, name,stateid } = useParams()
///:action/:name/:id/:state/:stateid
  const [images, setImages] = useState([]);
  const { setDetailItem,searchKeyword} = useAuth();

  const [page, setPage] = useState(1); // Inicializamos la página en 1
  const [loading, setLoading] = useState(false); // Estado para controlar la carga

  const searchProducts = async(page,search)=>{
    setLoading(true); // Activamos el estado de carga

    let result = await fetch_images(page,search)
    setLoading(false); // Activamos el estado de carga

    setImages(result.products)
  }
  useEffect(()=> { 
     if(searchKeyword.length>0) {
    setImages([])
    searchProducts(1,searchKeyword)
  }else{
    if(searchKeyword.length==0){
      setImages([])

      //more()
    }
  }
  } ,[searchKeyword])
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      if (windowHeight + scrollTop >= documentHeight * 0.9 && !loading) {
       more();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading]); // Agregamos loading como dependencia del efecto

  const more = async () => {
    setLoading(true); // Activamos el estado de carga
    const more_images = await fetch_products(id, stateid,page); // Utilizamos la página actual
    setPage((prevPage) => prevPage + 1); // Incrementamos la página
    setImages((prev) => [...prev, ...more_images.products]);
    setLoading(false); // Desactivamos el estado de carga
  };

  useEffect(() => {
    more(); // Cargar imágenes inicialmente
  }, []); // Eliminamos la dependencia para asegurarnos de que solo se ejecute una vez

  const nameProduct =(name)=>{
    let i=0;
    while(i==0){
        if(name.includes("-")){
            
            name=name.replace("-", " ");
            name=name.replace("/", " "); 

        }else{
            i=1
        }
    }
    return name;
  }
  ///detalle/:category/:id/:name/:description
  return (
    <div className="container">
      <Loading loading={loading}/>
      <div className="row">
        {images.map((item, index) => (
          (item.imageUrl? 
          <div className="col-sm-3 " key={index} style={{ paddingTop: "20px" }}>
            <div key={item.id} className="card section-center">
              <Link
               to={`/detalle/${item.category?.nameSpanish}/${nameProduct(item.name)}/${item.state?.name}/${item.id}`} style={{textAlign:'center'}}
                onClick={() => setDetailItem(item)}
              >
                <img src={item.imageUrl? item.imageUrl : '/img/imagendummy.png'} className="img-produc " alt="..." />
                <div class="card-body" style={{ textAlign: "center" }}>
                  <h3 class="card-title text-produc">{item.name}</h3>

                  <h6 class="card-title" style={{ color: "green" }}>
                    Existencias {item.stock}
                  </h6>
                 
                  <h6 class="card-title  text-produc" style={{ color: "orange" }}>
                    {item.description}
                  </h6>

                  <h6 class="card-title" style={{ color: "red" }}>
                    Ubicación {item.state?.name}
                  </h6>
                  <h6 class="card-title" style={{ color: "black" }}>
                    Entrega de 3 a 15 días
                  </h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
</svg> Venta | Renta
                </div>
              </Link>

              <div class="card-body " style={{ textAlign: "center" }}>
                <a href={"/contact/" + nameProduct(item.name)}>
                  <button style={{ width: "200px" }} className="btn btn-primary">
                    Cotizar ahora
                  </button>
                </a>
              </div>
            </div>
          </div>:"")
        ))}
      </div>
    </div>
  );
};

const fetch_products = async (categoryId,stateId,page) =>
  (await fetch(`${process.env.REACT_APP_URL}genie/search?categoryId=${(categoryId==0? '' :categoryId)}&stateId=${(stateId ==0? '': stateId)}&page=${page}`)).json()


import "./Presentation.css";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import { Search } from "../search";
export const Presentation = () => {
  return (
    <section className="section">
      <div className="home">
        <div className="container">
          <h1 className="main-title" style={{ textAlign: "center" }}>
            Block Barato
            <span
              data-aos="fade-up"
              style={{ textAlign: "center" }}
              data-aos-delay="400"
            ></span>
            <span
              data-aos="fade-up"
              style={{ fontSize: 25, textAlign: "center" }}
              data-aos-delay="500"
            >
              Construyendo sueños desde las alturas.
            </span>
          </h1>
           <Search />
        </div>
        
      </div>
      <div className="background">
        <MDBCarousel fade>
          {/* <MDBCarouselItem itemId={1}>
            <img
              className="video"
              src={"/img/img_servicios/principal.webp"}
              alt="Personal y Soporte Técnico"
            />
          </MDBCarouselItem> */}
          <MDBCarouselItem itemId={1}>
            <img
              className="video"
              src={"/img/img_servicios/gruas.JPG"}
              alt="Personal y Soporte Técnico"
            />
          </MDBCarouselItem>
          {/* <MDBCarouselItem itemId={3}>
            <img
              className="video"
              src={"/img/img_servicios/gruas.JPG"}
              alt="Personal y Soporte Técnico"
            />
          </MDBCarouselItem>

          <MDBCarouselItem itemId={4}>
            <img
              className="video"
              src={"/img/img_servicios/zoomlion.JPG"}
              alt="Personal y Soporte Técnico"
            />
          </MDBCarouselItem>  */}
        </MDBCarousel> 
        {/* <img className="video" src={'/img/img_servicios/principal.jpg'} alt="Personal y Soporte Técnico"/> */}

        {/* <video className="video"  autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">
          <source src={process.env.REACT_APP_URL_PELICULA} type="video/mp4"/>
          </video>  */}
      </div>
    </section>
  );
};
